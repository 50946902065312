kv.controller('trademarkListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'trademark_id';
    vm.entity = 'trademark';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'trademark'
        },
        url: "trademark",
        callback: function (response) {
            vm.countLines = 0;
            if (!response.data) return;
            vm.countLines = (response.data ? response.total : 0);
        }
    });

    vm.moveEntities = function () {
        vm.openModal({
            templateUrl: 'trademark-list-move',
            controller: TrademarkListMoveCtrl,
            size: 'md',
            resolve: {
                data: function () {
                    return {
                        searchList: vm.infinitScroll.search,
                        countLines: vm.countLines,
                    };
                }
            },
            backdrop: 'static',
            keyboard: false
        }).then(response => {
            if (response == "ok") vm.infinitScroll.search.refresh++;
        });
    };

    return vm;
});

