kv.controller('TrademarkImportCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'trademark_id';
    vm.entity = 'trademark';
    vm.filter.value = "";
    vm.rest = vm.getInjection('resource').init('trademark');
    vm.data=[];
    vm.data.trademarks = [];
    vm.data.trademarks_copy = [];
    vm.searchText = '';
    vm.max_trademarks_count = JSON.parse(vm.bladeParams.maxTrademarks);
    vm.current_trademarks_count = JSON.parse(vm.bladeParams.currentTrademarksCount);
    vm.data = {
        responsible_id: API.user.user_id,
        responsible: {
            full_name: API.user.full_name
        },
        own_portfolio: 1
    };

    /**
     * search trademarks
     */
    vm.findTrademarks = function () {
        KApp.block('.details');
        vm.rest.get({
            url: 'trademark/searchTrademarkOnOsim',
            params: {
                deposit_date_from: vm.infinitScroll.search.deposit_date_from ? vm.infinitScroll.search.deposit_date_from : null,
                deposit_date_to: vm.infinitScroll.search.deposit_date_to ? vm.infinitScroll.search.deposit_date_to : null,
                id: vm.infinitScroll.search.id ? vm.infinitScroll.search.id : null,
                deposit_number: vm.infinitScroll.search.q ? vm.infinitScroll.search.q : null,
                trademark_name: vm.infinitScroll.search.trademark_name ? vm.infinitScroll.search.trademark_name : null,
                holder_name: vm.infinitScroll.search.holder_name ? vm.infinitScroll.search.holder_name : null,
                representative_name: vm.infinitScroll.search.representative_name ? vm.infinitScroll.search.representative_name : null,
                nice_class: vm.infinitScroll.search.nice_class ? vm.infinitScroll.search.nice_class : null,
            }
        }).then(response => {
            if (response.data) {
                KApp.unblock('.details');
                vm.data.trademarks = response.data;
                vm.data.new_items_count = response.totals.new ?? 0;
                vm.data.existing_items_count = response.totals.existing ?? 0;
                vm.data.updated_items_count = response.totals.updated ?? 0;
                vm.data.total_count = response.totals.total ?? 0;
                vm.data.trademarks_copy = vm.data.trademarks;


                // map the data.data and add status_class based on import_status
                vm.data.trademarks = vm.data.trademarks.map(function(entity) {
                    switch(entity.import_status) {
                        case 'new':
                            entity.status_class = 'badge-success';
                            break;
                        case 'existing':
                            entity.status_class = 'badge-danger';
                            break;
                        case 'error':
                            entity.status_class = 'badge-warning';
                            break;
                        default:
                            entity.status_class = 'badge-secondary';
                    }
                    return entity;
                });
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            KApp.unblock('.details');
        });
    };

    // Update selection for each checkbox
    vm.updateSelection = function(entity, index) {
        vm.selectedItems = vm.data.trademarks.every(function(entity) {
            return entity.is_selected;
        });
    };

    vm.doImport = function(dataToSend) {
        if(vm.max_trademarks_count < (vm.current_trademarks_count + dataToSend.length)) {
            toastr.error(vm.trans('MAX_NUMBER_OF_TRADEMARKS_REACHED'));
            return;
        }

        KApp.block(".table-import");

        swal({
            // position: 'top-right',
            type: 'info',
            title: 'Confirmati importul celor '+ dataToSend.length +' de marci selectate? ',
            showConfirmButton: true,
            showCancelButton: true,
            buttons: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                vm.rest.post({
                    url: 'trademark/actions/import_trademarks_from_osim',
                    params: {
                        action: 'import_trademarks_from_osim'
                    },
                    data: {
                        data : dataToSend,
                        filter: {
                            responsible_id:  vm.data.responsible_id ? vm.data.responsible_id : null,
                            own_portfolio:  vm.data.own_portfolio ? vm.data.own_portfolio : 1
                        }

                    }
                }).then(function (response) {
                    KApp.unblock(".table-import");
                    swal.close();
                    if(response.status) {
                        toastr.success(response.message);


                        // Remove the imported items from the list
                        vm.data.trademarks = vm.data.trademarks.filter(function(entity) {
                            // Only keep items that were not selected for import
                            return !entity.is_selected;
                        });

                        // Optionally, reset the selected status for remaining items
                        vm.data.trademarks.forEach(function(entity) {
                            entity.is_selected = false;
                        });
                    }
                });
            } else {
                swal.close();
            }
        });

    };

    vm.importSelected = function() {
        if (Array.isArray(vm.data.trademarks)) {  // Ensure vm.entities is an array
            // Get all selected entities
            var selectedEntities = vm.data.trademarks.filter(function(entity) {
                return entity.is_selected;
            });

            var dataToSend = selectedEntities.map(function(entity) {
                return entity;
            });

            vm.doImport(dataToSend);
        }
    };

    vm.importSelectedItem = function(entity, index) {
        var dataToSend = [entity]; // Wrap the single entity in an array
        vm.doImport(dataToSend);
    };

    vm.selectAll = function() {
        if (Array.isArray(vm.data.trademarks)) {
            // Set all items' is_selected property to true
            vm.data.trademarks.forEach(function(entity) {
                entity.is_selected = true;
            });

            // Update the selectAll state
            vm.selectAllItems = true;
        }
    };

    vm.uncheckAll = function() {
        if (Array.isArray(vm.data.trademarks)) {
            // Set all items' is_selected property to true
            vm.data.trademarks.forEach(function(entity) {
                entity.is_selected = false;
            });

            // Update the selectAll state
            vm.selectAllItems = false;
        }
    };

    // Function to select only items with state = "new"
    vm.selectNew = function() {
        if (Array.isArray(vm.data.trademarks)) {
            // Iterate through all items
            vm.data.trademarks.forEach(function(entity) {
                entity.is_selected = entity.import_status === "new"; // Mark as selected if status is "new"
            });

            // Update the selectAll state
            vm.selectedItems = vm.data.trademarks.every(function(entity) {
                return entity.is_selected;
            });
        }
    };

    vm.filterList = function (status) {
        if (status) {
            // Reset `vm.data.items` to the original list before applying the filter
            vm.data.trademarks = angular.copy(vm.data.trademarks_copy);

            // Filter items based on the status
            if (status === 'new') {
                vm.data.trademarks = vm.data.trademarks.filter(function (item) {
                    return item.import_status === 'new';
                });
            }
            if (status === 'existing') {
                vm.data.trademarks = vm.data.trademarks.filter(function (item) {
                    return item.import_status === 'existing';
                });
            }
            if (status === 'error') {
                vm.data.trademarks = vm.data.trademarks.filter(function (item) {
                    return item.import_status === 'error';
                });
            }
            if (status === 'all') {
                vm.data.trademarks = angular.copy(vm.data.trademarks_copy); // Reset to all items
            }
        }
    };

    vm.searchFilter = function (entity) {
        var searchTerm = vm.infinitScroll.search.q ? vm.infinitScroll.search.q.toLowerCase() : '';
        return (entity.trademark_name && entity.trademark_name.toLowerCase().includes(searchTerm)) ||
            (entity.holders && entity.holders.toLowerCase().includes(searchTerm));
    };

    return vm;
});

